import http from "../axios-instance";

class RegVehicleUvcMain {
getTotalDataByRegNo(regNo) {
    return http
      .get(`/reg-search/vehicle-main/uvc-final?vehicle_reg_no=${regNo}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getTotalUvcData(uvcId) {
    return http
      .get(`/reg-search/vehicle-main/${uvcId}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateSingleUvcData(id,data) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return http
      .put(`/reg-search/vehicle-main/${id}`, data, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

 
}

export default new RegVehicleUvcMain();
